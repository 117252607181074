/* line 11, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
.u-ar__content .map {
	width: 100%;
	height: 100%;
}

/* line 17, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
.m-tabs-data .c-tabs {
	margin-bottom: 0;
}

/* line 21, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
.m-tabs-data .details__map {
	min-height: 300px;
}

/* line 24, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
.m-tabs-data .details__map .map {
	height: 100%;
}

/* line 29, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
.m-tabs-data__border {
	border-bottom: 1px solid rgb(var(--color-global-border));
}

/* line 37, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
.m-tabs-data .splitter-toggle__container.map-control {
	position: absolute;
	z-index: 99;
}

/* line 42, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
.m-tabs-data .tabs-select-wrapper {
	text-transform: capitalize;
}

/* line 47, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
.m-tabs-data.splitter-layout .agent-tabs-data__agent-title {
	padding: 5px 0 15px 0;
	text-transform: capitalize;
	color: rgb(var(--color-dynamic-primary-color));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-tabs-data.splitter-layout .agent-tabs-data__agent-title {
	padding: 5px 0 15px 0;
}

/* line 53, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
.m-tabs-data.splitter-layout .resultssort-container .selectboxit-container {
	width: auto;
	min-width: 70%;
	max-width: 100%;
}

@media only screen and (min-width: 120em) {
	/* line 53, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
	.m-tabs-data.splitter-layout .resultssort-container .selectboxit-container {
		min-width: 50%;
	}
}

/* line 66, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
.marker__link:hover {
	color: rgb(var(--color-global-secondary));
}

/* line 72, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
.gm-style-iw-container > div:last-child {
	background: rgb(var(--color-global-body)) !important;
}

/* line 76, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
.gm-style-iw-container > div:nth-child( + 3) > div > div {
	background-color: rgb(var(--color-global-body)) !important;
}

/* line 81, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
.agent-tabs-data__title {
	margin-bottom: 20px;
	text-transform: capitalize;
	display: block;
}

/* line 94, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
.agenttabs--wrapper .agent-tabs-data__title {
	display: none;
}

/* line 99, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
.agent-tabs-data__subtitle {
	margin-bottom: 40px;
}

/* line 107, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
.agent-tabs-data__tabs-container {
	border-bottom: 1px solid rgb(var(--color-global-border));
}

/* line 114, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
.agent-tabs-data__agent-title {
	text-align: center;
	font-weight: 400;
}

/* line 122, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
.agenttabs--wrapper .m-agent-tabs-data {
	padding: 0 0 0 0;
	margin: 0 -20px 0 -20px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .agenttabs--wrapper .m-agent-tabs-data {
	padding: 0 0 0 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .agenttabs--wrapper .m-agent-tabs-data {
	margin: 0 -20px 0 -20px;
}

@media only screen and (max-width: 47.999em) {
	/* line 127, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
	.agenttabs--wrapper .m-agent-tabs-data .c-paging--count {
		display: none !important;
	}
}

/* line 132, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
.agenttabs--wrapper .m-agent-tabs-data .m-tabs-data__border {
	display: inline-block;
	width: 100%;
	background: rgb(var(--color-global-title));
	padding-top: 10px;
	border-bottom: 0;
}

/* line 139, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
.agenttabs--wrapper .m-agent-tabs-data .m-tabs-data__border .m-tabs-data__container {
	float: left;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .agenttabs--wrapper .m-agent-tabs-data .m-tabs-data__border .m-tabs-data__container {
	float: right;
}

@media only screen and (max-width: 47.999em) {
	/* line 139, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
	.agenttabs--wrapper .m-agent-tabs-data .m-tabs-data__border .m-tabs-data__container {
		padding-left: 0;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .agenttabs--wrapper .m-agent-tabs-data .m-tabs-data__border .m-tabs-data__container {
		padding-left: initial;
		padding-right: 0;
	}
}

/* line 147, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
.agenttabs--wrapper .m-agent-tabs-data .m-tabs-data__border .sort-container {
	max-width: 250px;
}

/* line 151, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
.agenttabs--wrapper .m-agent-tabs-data .m-tabs-data__border.is-full {
	width: 100%;
}

@media only screen and (max-width: 47.999em) {
	/* line 132, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
	.agenttabs--wrapper .m-agent-tabs-data .m-tabs-data__border {
		padding: 10px 20px 0 20px;
		width: 95%;
		box-sizing: border-box;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .agenttabs--wrapper .m-agent-tabs-data .m-tabs-data__border {
		padding: 10px 20px 0 20px;
	}
}

/* line 162, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
.agenttabs--wrapper .m-agent-tabs-data .grid.is-ingrid {
	padding: 10px 0 10px 0;
	margin: 0 0 0 0;
	display: inline-block;
	background: rgb(var(--color-global-title));
	width: 15%;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .agenttabs--wrapper .m-agent-tabs-data .grid.is-ingrid {
	padding: 10px 0 10px 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .agenttabs--wrapper .m-agent-tabs-data .grid.is-ingrid {
	margin: 0 0 0 0;
}

@media only screen and (max-width: 47.999em) {
	/* line 162, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
	.agenttabs--wrapper .m-agent-tabs-data .grid.is-ingrid {
		display: none;
	}
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
	/* line 162, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
	.agenttabs--wrapper .m-agent-tabs-data .grid.is-ingrid {
		padding: 10px 0 9px 0;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .agenttabs--wrapper .m-agent-tabs-data .grid.is-ingrid {
		padding: 10px 0 9px 0;
	}
}

/* line 179, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
.agenttabs--wrapper .m-agent-tabs-data .grid.is-ingrid .grid__item {
	padding: 0 20px 0 10px;
	width: 100%;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .agenttabs--wrapper .m-agent-tabs-data .grid.is-ingrid .grid__item {
	padding: 0 10px 0 20px;
}

/* line 185, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
.agenttabs--wrapper .m-agent-tabs-data .hfeed.gallery {
	padding: 0 20px 0 20px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .agenttabs--wrapper .m-agent-tabs-data .hfeed.gallery {
	padding: 0 20px 0 20px;
}

/* line 189, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
.agenttabs--wrapper .m-agent-tabs-data .tabs__item {
	padding: 10px 40px 10px 40px;
	background-color: rgb(var(--color-global-primary));
	border: 1px solid rgb(var(--color-global-border));
	border-radius: 10px 10px 0 0;
	font-weight: bold;
	color: #5b5b5b;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .agenttabs--wrapper .m-agent-tabs-data .tabs__item {
	padding: 10px 40px 10px 40px;
}

/* line 206, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
.agenttabs--wrapper .m-agent-tabs-data .tabs__item--selected {
	background-color: rgb(var(--color-global-positive));
	color: rgb(var(--color-global-primary));
	border-bottom: 1px solid rgb(var(--color-global-positive));
}

@media only screen and (min-width: 48em) {
	/* line 212, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
	.agenttabs--wrapper .m-agent-tabs-data .tabs__item:first-child {
		margin-left: 20px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .agenttabs--wrapper .m-agent-tabs-data .tabs__item:first-child {
		margin-left: initial;
		margin-right: 20px;
	}
}

@media only screen and (min-width: 48em) and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .agenttabs--wrapper .m-agent-tabs-data .tabs__item:first-child {
		margin-left: 0;
	}
}

/* line 219, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
.agenttabs--wrapper .m-agent-tabs-data .c-listing-item--nyc .listing-item__name {
	line-height: 17px;
	margin-bottom: 5px;
}

/* line 224, scss/80-themes/Saunders/70-modules/agenttabsdata/module.scss */
.agenttabs--wrapper .m-agent-tabs-data .c-paging--count {
	margin-top: 5px;
}

/*# sourceMappingURL=../../../../../true */